import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../modules/common/components/section-header';

import '../generic-page.scss';
import './characters.scss';
import { IReverseSkin } from '../../modules/common/model/graphql-types';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import lodash from 'lodash';
import { Reverse1999Skin } from '../../modules/reverse/common/components/rev-skin';

interface IReverseCharacterNodes {
  nodes: IReverseSkin[];
}

interface IReverseCharacterEntry {
  allCharacters: IReverseCharacterNodes;
}

interface IProps {
  data: IReverseCharacterEntry;
}

const ReverseSkinsPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search skins...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: '3✦', value: '3' },
          { label: '4✦', value: '4' },
          { label: '5✦', value: '5' },
          { label: '6✦', value: '6' }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.characterName
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'skinId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.slug > b.slug ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.slug < b.slug ? 1 : -1));
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout
      className={'generic-page characters-page-nikke characters-reverse'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>Skins</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/reverse/categories/category_skins.jpg"
            alt="Skins"
          />
        </div>
        <div className="page-details">
          <h1>Reverse: 1999 Skins</h1>
          <h2>List of available skins in Reverse: 1999.</h2>
          <p>
            Last updated: <strong>20/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Skins"
        subtitle={
          <span className="additional-text">
            Showing {visibleCharacters.length} skins
          </span>
        }
      />
      <div className="employees-filter-bar">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className={`employees-container`}>
        {visibleCharacters.map((emp, index) => {
          return (
            <span key={index}>
              <Card className="avatar-card">
                <Reverse1999Skin slug={emp.slug} mode="card" showLabel />
              </Card>
            </span>
          );
        })}
      </div>
      {visibleCharacters.length === 0 && (
        <div className="no-results">
          <StaticImage
            src="../../images/reverse/no_results.webp"
            alt="No results"
          />
          <p>No skins found. Try changing your filter.</p>
        </div>
      )}
      <br />
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default ReverseSkinsPage;

export const Head: React.FC = () => (
  <Seo
    title="Skins | Reverse: 1999 | Prydwen Institute"
    description="List of available skins in Reverse: 1999 - both in Global and CN versions."
    game="reverse"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulReverseSkin(
      sort: { fields: slug, order: DESC }
    ) {
      nodes {
        id
        skinId
        name
        characterName
        slug
        rarity
        type
        availableInGlobal
        patch
      }
    }
  }
`;
